import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SHOW_LOADING,
  STOP_LOADING,
  GET_USER_DETAILS,
  REGISTER_EMAIL,
  REGISTER_ID,
  NOTIFICATION_COUNT,
  ALERTS_COUNT,
  REFRESH_NOTIFICATIONS_FUNC,
  REFRESH_ALERTS_FUNC,
  HELP,
  REFRESH_COMPANY_FEATURES,
} from '../constants/Auth'

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  }
}

export const authenticated = (
  token,
  name,
  role,
  companyId,
  isTrialUser,
  company,
  assignedGroups,
  anonymizeAuditLogs
) => {
  return {
    type: AUTHENTICATED,
    token,
    role,
    name,
    companyId,
    isTrialUser,
    company,
    assignedGroups,
    anonymizeAuditLogs
  }
}

export const signOut = () => {
  return {
    type: SIGNOUT,
  }
}
export const getUserDetails = () => {
  return {
    type: GET_USER_DETAILS,
  }
}

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  }
}

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  }
}

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  }
}

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  }
}

export const stopLoading = () => {
  return {
    type: STOP_LOADING,
  }
}

export const setRegisterEmail = (email) => {
  return {
    type: REGISTER_EMAIL,
    email,
  }
}
export const setOnBoardingId = (onBoardingId) => {
  return {
    type: REGISTER_ID,
    onBoardingId,
  }
}

export const setNotificationsCount = (count) => {
  return {
    type: NOTIFICATION_COUNT,
    count,
  }
}

export const setAlertsCount = (count) => {
  return {
    type: ALERTS_COUNT,
    count,
  }
}
export const refreshNotificationsFunc = (refreshFunc) => {
  return {
    type: REFRESH_NOTIFICATIONS_FUNC,
    refreshFunc,
  }
}
export const refreshAlertsFunc = (refreshFunc) => {
  return {
    type: REFRESH_ALERTS_FUNC,
    refreshFunc,
  }
}
export const refreshFeaturesFunc = (refreshFunc) => {
  return {
    type: REFRESH_COMPANY_FEATURES,
    refreshFunc,
  }
}
export const setHelp = (help) => {
  return {
    type: HELP,
    help,
  }
}
