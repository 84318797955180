import { isEmpty } from 'lodash'

export const formatFiltersForRest = (filters) => {
  let string = ''

  // if no filter just return empty string
  if (!filters || isEmpty(filters)) return string
  const flattenFilters = flattenJsonObject(filters)

  Object.keys(flattenFilters).forEach((key) => {
    // let string = ''
    let filterKeys = key.split('.')
    let keyName = ''
    let condition = ''
    // remove lastIndex if it is upper/lower , integer index also because it means they're arrays
    if (
      filterKeys[filterKeys.length - 1] === 'upper' ||
      filterKeys[filterKeys.length - 1] === 'lower' ||
      parseInt(filterKeys[filterKeys.length - 1])
    ) {
      filterKeys = filterKeys.slice(0, filterKeys.length - 1)
    }
    // last index is now the filter condition
    keyName = filterKeys.slice(0, filterKeys.length - 1).join('.')
    condition = filterKeys[filterKeys.length - 1]
    // here check if the string already contains same key and condition then append
    //  the value (which is the second date)
    if (string.includes(`&filter=${keyName}||${condition}`)) string += `,${flattenFilters[key]}`
    else string += `&filter=${keyName}||${condition}||${flattenFilters[key]}`
  })
  return string
}

export const flattenJsonObject = (data) => {
  var result = {}
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        // eslint-disable-next-line
        recurse(cur[i], prop ? prop + '.' + `${i + 1}` : '' + i)
      if (l === 0) result[prop] = []
    } else {
      var isEmpty = true
      for (var p in cur) {
        isEmpty = false
        recurse(cur[p], prop ? prop + '.' + p : p)
      }
      if (isEmpty) result[prop] = {}
    }
  }
  recurse(data, '')
  return result
}

export const formatAlertAdvancedFilter = (filters) => {
  if (filters)
    return filters.reduce((queryString, filter, index) => {
      const { joinAction = '', filterType, filterAction, filterValue } = filter
      let adjustedAction = filterAction
      let valueString
      if (filterAction === 'singleDate') {
        adjustedAction = 'between'
        const date = new Date(filterValue)
        const startOfDay = new Date(date.setUTCHours(0, 0, 0, 0)).toISOString()
        const endOfDay = new Date(date.setUTCHours(23, 59, 59, 999)).toISOString()
        valueString = `${startOfDay},${endOfDay}`
      } else if (filterAction === 'dateRange' && Array.isArray(filterValue)) {
        adjustedAction = 'between'
        const startDate = new Date(new Date(filterValue[0]).setUTCHours(0, 0, 0, 0)).toISOString()
        const endDate = new Date(
          new Date(filterValue[1]).setUTCHours(23, 59, 59, 999),
        ).toISOString()
        valueString = `${startDate},${endDate}`
      } else {
        valueString = Array.isArray(filterValue) ? filterValue.join(',') : filterValue
      }
      // Format the filter segment based on joinAction
      const filterSegment = `${filterType}||${adjustedAction}||${valueString}`
      // If joinAction is &&, start a new filter block; otherwise, concatenate with previous
      return (
        queryString +
        (joinAction === '&' ? `&filter=${filterSegment}` : `${joinAction}${filterSegment}`)
      )
    }, '&filter=')
}
