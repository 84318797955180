import fetch from 'auth/FetchInterceptor'
import { TableSorting } from 'utils/enum'
import { formatFiltersForRest } from 'utils/filter'

export const getAllUsers = (
  pageSize,
  pageNumber,
  filters,
  sorting,
  groupFilter = '',
  search = '',
  groupId = '',
) => {
  const allFilter = { ...filters, ...groupFilter }
  if (groupId) {
    if (!allFilter['groupId']) allFilter['groupId'] = {}
    if (!allFilter['groupId'].eq) allFilter['groupId'].eq = groupId
  }

  let formattedFilters = formatFiltersForRest(allFilter)
  let sortingStr = ''
  if (sorting.sortField && sorting.sortOrder)
    sortingStr = `&sort=${sorting.sortField},${TableSorting[sorting.sortOrder]}`

  return fetch({
    url: `clients?limit=${pageSize}&page=${pageNumber}${sortingStr}&join=group&join=clientUploads&join=historyTrackingAgreements${formattedFilters}${
      search && `&filter=fullIdentity||$contL||${search}`
    }`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
export const getAllAdminUsers = (pageSize, pageNumber, filters, sorting, search = '') => {
  let formattedFilters = formatFiltersForRest(filters)
  let sortingStr = ''
  if (sorting.sortField && sorting.sortOrder)
    sortingStr = `&sort=${sorting.sortField},${TableSorting[sorting.sortOrder]}`

  return fetch({
    url: `users?join=assignedGroups&limit=${pageSize}&page=${pageNumber}${formattedFilters}${sortingStr}${
      search && `&filter=fullIdentity||$contL||${search}`
    }`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getAllUsersWithoutPaging = () => {
  return fetch({
    url: `clients?fields=login&sort=login,ASC`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getAllUsersWithEmailFilter = (search) => {
  return fetch({
    url: `clients?limit=100&fields=login&sort=login,ASC${
      search ? '&filter=login||$contL||' + search : ''
    }`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
export const createOneClient = (data) => {
  return fetch({
    url: 'clients',
    method: 'post',
    data,
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
export const importCSVClients = (file) => {
  return fetch({
    url: `clients/import-from-csv`,
    method: 'post',
    data: file,
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
export const createOneUser = (data) => {
  return fetch({
    url: 'users',
    method: 'post',
    data,
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
export const updateOneUser = (data) => {
  return fetch({
    url: 'auth/reset-password',
    method: 'post',
    data,
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const changePasswordUser = (data) => {
  return fetch({
    url: 'auth/change-password',
    method: 'post',
    data,
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const requestPasswordChange = (clientId) => {
  return fetch({
    url: `clients/request-password-reset/${clientId}`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const resetOTPFactor = (clientId) => {
  return fetch({
    url: `clients/reset-otp-factor/${clientId}`,
    method: 'post',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const assignGroupsToUser = (data) => {
  return fetch({
    url: `users/assign-groups-user`,
    method: 'post',
    data,
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
