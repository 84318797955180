import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import {
  ASSIGNED_GROUPS,
  ASSIGNED_GROUPS_IDS,
  AUTH_ID,
  AUTH_TOKEN,
  GET_USER_DETAILS,
  GROUP_ID,
  GROUP_ID_ADVANCED_POLICY,
  GROUP_ID_DASHBOARD,
  SIGNIN,
  SIGNOUT,
} from '../constants/Auth'
import { showAuthMessage, authenticated, signOutSuccess, showLoading } from '../actions/Auth'
import { setMfaData } from '../actions/LocalMfa'
import JwtAuthService from 'services/JwtAuthService'

export function* signInEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload
    try {
      const user = yield call(JwtAuthService.login, { email, password })
      if (user.message) {
        yield put(showAuthMessage(user.message))
      } else if (user.mfa) {
        yield put(setMfaData(user))
      } else {
        localStorage.setItem(AUTH_TOKEN, user.accessToken)
        localStorage.setItem(AUTH_ID, user.id)
        const name = `${user.title}${user.firstName} ${user.lastName}`
        const role = user.role ? user.role : 'Admin'
        const isTrialUser = user.isTrialUser ?? false
        const assignedGroups = user?.assignedGroups ?? []
        const anonymizeAuditLogs = user.anonymizeAuditLogs ?? false
        
        if (assignedGroups.length) {
          localStorage.setItem(ASSIGNED_GROUPS, JSON.stringify(assignedGroups))
          localStorage.setItem(ASSIGNED_GROUPS_IDS, assignedGroups.map((g) => g.id).toString())
          localStorage.setItem(GROUP_ID_DASHBOARD, assignedGroups[0].id)
          localStorage.setItem(GROUP_ID, assignedGroups[0].id)
          localStorage.setItem(GROUP_ID_ADVANCED_POLICY, assignedGroups[0].id)
        }
        yield put(
          authenticated(
            user.accessToken,
            name,
            role,
            undefined,
            isTrialUser,
            undefined,
            assignedGroups,
            anonymizeAuditLogs
          ),
        )
      }
    } catch (err) {
      yield put(showAuthMessage(err.message))
    }
  })
}

export function* getDetails() {
  yield takeEvery(GET_USER_DETAILS, function* () {
    try {
      const userId = localStorage.getItem(AUTH_ID)
      const userToken = localStorage.getItem(AUTH_TOKEN)
      if (userId) {
        yield call(showLoading)
        const user = yield call(JwtAuthService.getDetails, userId)
        const name = `${user.title ? user.title : ''}${user.firstName} ${user.lastName}`
        const role = user.role ? user.role : 'Admin'
        const companyId = user.companyId || undefined
        const isTrialUser = user.isTrialUser || false

        if (!user.message)
          yield put(authenticated(userToken, name, role, companyId, isTrialUser, user.company, null, user.anonymizeAuditLogs))
      }
    } catch (err) {
      yield put(showAuthMessage(err))
    }
  })
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = undefined
      if (signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN)
        localStorage.removeItem(AUTH_ID)
        localStorage.removeItem(ASSIGNED_GROUPS)
        yield put(signOutSuccess(signOutUser))
      } else {
        yield put(showAuthMessage(signOutUser.message))
      }
    } catch (err) {
      yield put(showAuthMessage(err))
    }
  })
}

export default function* rootSaga() {
  yield all([fork(signInEmail), fork(signOut), fork(getDetails)])
}
