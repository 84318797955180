import fetch from 'auth/FetchInterceptor'
import { ASSIGNED_GROUPS_IDS } from 'redux/constants/Auth'
import { TableSorting } from 'utils/enum'
import { formatAlertAdvancedFilter, formatFiltersForRest } from 'utils/filter'

export const getAllViolations = (pageSize, pageNumber, filters, sorting, isNew) => {
  let localFilters = { ...filters }
  const assignedGroups = localStorage.getItem(ASSIGNED_GROUPS_IDS)

  if (!localFilters) localFilters = { status: { [isNew ? 'in' : 'notin']: ['New'] } }
  if (localFilters && !localFilters?.status)
    localFilters.status = { [isNew ? 'in' : 'notin']: ['New'] }
  if (assignedGroups) {
    if (!localFilters['groupId']) localFilters['groupId'] = {}
    if (!localFilters['groupId'].in) localFilters['groupId'].in = assignedGroups
  }
  let formattedFilters = formatFiltersForRest(localFilters)

  let sortingStr = ''
  if (sorting.sortField && sorting.sortOrder)
    sortingStr = `&sort=${sorting.sortField},${TableSorting[sorting.sortOrder]}`
  return fetch({
    url: `access-violation?join=client&limit=${pageSize}&page=${pageNumber}${sortingStr}${formattedFilters}`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getAllViolationsNEW = (
  pageSize,
  pageNumber,
  filters,
  sorting,
  search = '',
  advancedFilter,
) => {
  let localFilters = { ...(filters || {}) }
  const assignedGroups = localStorage.getItem(ASSIGNED_GROUPS_IDS)
  if (assignedGroups) {
    // if (!localFilters['client.groupId']) localFilters['client.groupId'] = {}
    // if (!localFilters['client.groupId'].in) localFilters['client.groupId'].in = assignedGroups
    if (!localFilters['groupId']) localFilters['groupId'] = {}
    if (!localFilters['groupId'].in) localFilters['groupId'].in = assignedGroups
  }
  let formattedFilters = formatFiltersForRest(localFilters)
  let formattedAdvancedFilter = formatAlertAdvancedFilter(advancedFilter) || ''
  let sortingStr = ''
  if (sorting.sortField && sorting.sortOrder)
    sortingStr = `&sort=${sorting.sortField},${TableSorting[sorting.sortOrder]}`
  return fetch({
    url: `access-violation?join=client&limit=${pageSize}&page=${pageNumber}${sortingStr}${formattedFilters}${
      search && `&filter=client.fullIdentity||$contL||${search}`
    }${formattedAdvancedFilter}`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getViolationForDashboard = ({ last7Day, search = '', groupId }) => {
  let localFilters = {}
  const assignedGroups = localStorage.getItem(ASSIGNED_GROUPS_IDS)
  if (assignedGroups) {
    if (!localFilters['groupId']) localFilters['groupId'] = {}
    if (!localFilters['groupId'].in) localFilters['groupId'].in = assignedGroups
  }
  let formattedFilters = formatFiltersForRest(localFilters)
  return fetch({
    url: `access-violation?fields=policy,client,createdAt,updatedAt,status,restrictedResource,restrictedResourceName,classificationName&join=client||full_identity,groupId&sort=updatedAt,DESC&filter=updatedAt||gte||${last7Day}&filter=policy||notnull${
      search && `&filter=client.full_identity||$contL||${search}`
    }${groupId && `&filter=groupId||eq||${groupId}`}${formattedFilters}`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}
export const getViolationResolvedForDashboard = ({ last7Day }) => {
  let localFilters = {}
  const assignedGroups = localStorage.getItem(ASSIGNED_GROUPS_IDS)
  if (assignedGroups) {
    if (!localFilters['groupId']) localFilters['groupId'] = {}
    if (!localFilters['groupId'].in) localFilters['groupId'].in = assignedGroups
  }
  let formattedFilters = formatFiltersForRest(localFilters)
  return fetch({
    url: `access-violation?fields=policy,createdAt,status&sort=createdAt,DESC&filter=createdAt||gte||${last7Day}&join=client||full_identity,groupId${formattedFilters}`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}

export const getDailyAlerts = ({ count, search, groupId }) => {
  return fetch({
    url: `dashboard/daily-alerts?count=${count}${search && `&filter=search||eq||${search}`}${
      groupId && `&filter=groupId||eq||${groupId}`
    }`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}

export const updateAccessViolation = ({ id, info }) => {
  return fetch({
    url: `access-violation/${id}`,
    method: 'patch',
    data: {
      ...info,
    },
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
export const deleteOneAccessViolation = (id) => {
  return fetch({
    url: `access-violation/${id}`,
    method: 'delete',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
export const downloadAlertAsCsv = (filters) => {
  let localFilters = { ...(filters ?? {}) }
  const assignedGroups = localStorage.getItem(ASSIGNED_GROUPS_IDS)
  if (assignedGroups) {
    if (!localFilters['groupId']) localFilters['groupId'] = {}
    if (!localFilters['groupId'].in) localFilters['groupId'].in = assignedGroups
  }
  let formattedFilters = formatFiltersForRest(localFilters)
  return fetch({
    url: `access-violation/export-csv?${formattedFilters}`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getNewAlertsCount = () => {
  let localFilters = {}
  const assignedGroups = localStorage.getItem(ASSIGNED_GROUPS_IDS)
  if (assignedGroups) {
    if (!localFilters['groupId']) localFilters['groupId'] = {}
    if (!localFilters['groupId'].in) localFilters['groupId'].in = assignedGroups
  }
  let formattedFilters = formatFiltersForRest(localFilters)
  return fetch({
    url: `access-violation?filter=status||eq||New&fields=id,status&filter=classificationName||$exclL||Alert file download&filter=policy||notin||Login,Logout,Uninstall,WeakPassword&filter=classificationName||notin||Login,Logout,Uninstall,WeakPassword&join=client||full_identity,groupId${formattedFilters}`,
    method: 'get',
  })
    .then((data) => data?.data?.length || 0)
    .catch((err) => console.log(err))
}
