import {
  AUTH_TOKEN,
  AUTH_ID,
  GROUP_ID,
  OLD_GROUP_ID,
  POLICY_TAB,
  ASSIGNED_GROUPS,
  GROUP_ID_DASHBOARD,
  ASSIGNED_GROUPS_IDS,
} from 'redux/constants/Auth'

export function clearStorage() {
  localStorage.removeItem(AUTH_TOKEN)
  localStorage.removeItem(AUTH_ID)
  localStorage.removeItem(GROUP_ID)
  localStorage.removeItem(OLD_GROUP_ID)
  localStorage.removeItem(POLICY_TAB)
  localStorage.removeItem(ASSIGNED_GROUPS)
  localStorage.removeItem(GROUP_ID_DASHBOARD)
  localStorage.removeItem(ASSIGNED_GROUPS_IDS)
}
