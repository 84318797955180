import { SET_POLICIES_RULES_TARGETS } from 'redux/constants/General'

const initState = {
  targets: [],
}

const policyRuleTarget = (state = initState, action) => {
  switch (action.type) {
    case SET_POLICIES_RULES_TARGETS:
      return {
        ...state,
        targets: action.targets,
      }
    default:
      return state
  }
}

export default policyRuleTarget
