import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import {
  ASSIGNED_GROUPS_IDS,
  AUTH_TOKEN,
  GROUP_ID,
  GROUP_ID_ADVANCED_POLICY,
} from 'redux/constants/Auth'
import { notification } from 'antd'
import { signOut } from 'redux/actions/Auth'
import store from 'redux/store'
import SimpleCryto from 'simple-crypto-js'
import { PUBLIC_REQUEST_KEY, TOKEN_PAYLOAD_KEY } from 'constants/ApiConstant'
import { clearStorage } from 'utils/clearLocalStorage'
import { env } from 'configs/EnvironmentConfig'

const simpleCryto = new SimpleCryto(env.ENCRYPTION_KEY || '')
const isEncrypted = env.IS_ENCRYPTED === 'true'

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 600000,
})

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN)
    //eslint-disable-next-line
    let gId = undefined
    if (localStorage.getItem('DEMO_POLICY') === 'true' && localStorage.getItem('GROUP_ID_DEMO'))
      gId = localStorage.getItem('GROUP_ID_DEMO')
    else if (localStorage.getItem(GROUP_ID_ADVANCED_POLICY))
      gId = localStorage.getItem(GROUP_ID_ADVANCED_POLICY) // for the multi policy struct
    else gId = localStorage.getItem(GROUP_ID) // support old polict struct based on group
    /**
     * To support both Client and Admin initiated request,
     * Only add authorization headers if not already added (i.e client request)
     */
    const clientAuth = config.headers[TOKEN_PAYLOAD_KEY]

    if (!clientAuth && jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
    }
    if (gId) config.headers['groupId'] = gId
    const assignedGroupIds = localStorage.getItem(ASSIGNED_GROUPS_IDS)
    if (assignedGroupIds) {
      config.headers['assignedGroups'] = assignedGroupIds
    }

    if (!jwtToken && !clientAuth && !config.headers[PUBLIC_REQUEST_KEY]) {
      clearStorage()
      store.dispatch(signOut())
    }

    // encrypt the request headers and body? payload
    if (isEncrypted) {
      config.headers['X-Surf-Token'] = env.ENCRYPTION_VALUE

      if (config?.data) config.data = { data: simpleCryto.encrypt(config.data) }
    }

    return config
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: 'Error',
    })
    Promise.reject(error)
  },
)

// API response interceptor
service.interceptors.response.use(
  (response) => (isEncrypted ? simpleCryto.decrypt(response.data) : response.data),
  (error) => {
    let notificationParam = {
      message: '',
    }

    // request happened and server responded
    if (error.response) {
      notificationParam.message = error?.response?.data?.message?.toString()
      if (
        error.response.status === 401 ||
        error.response.status === 403 ||
        error?.response?.data?.message === "Cannot read properties of undefined (reading 'role')"
      ) {
        if (!['auth/sign-in', 'auth/manual-sign-in'].includes(error.response.config.url)) {
          notificationParam.message = 'Authentication Fail'
          notificationParam.description = 'Please login again'
          clearStorage()
          store.dispatch(signOut())
        }
      }
      if (error.response.status === 404) {
        notificationParam.message = 'Not Found'
      }
      if (error.response.status === 500) {
        notificationParam.message = 'Internal Server Error'
      }
      if (error.response.status === 508) {
        notificationParam.message = 'Time Out'
      }
      notification.destroy()
      notification.error(notificationParam)
      return Promise.reject(error.response.data)
    }
    // request is made but no response received or request triggered an error
    notification.error({ message: error.message })
    return Promise.reject(error)
  },
)

export default service
